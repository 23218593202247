/* eslint-disable react/no-array-index-key */
import {mergeRoutes} from "src/omnia/routes-composer";
import React, {lazy, Suspense} from "react";
import LoadingScreen from "./omnia/components/general/loading-screen";
import {Outlet} from "react-router";
import ServiceGuard from "./omnia/components/general/auth/ServiceGuard";
import {ServiceLayout} from "./omnia/components/layouts/service";
import OnIcon from "./omnia/components/elements/icon";

// BoostAd Routes
const Overview = lazy(() => import('src/views/overview'));
const AccessAccounts = lazy(() => import('src/views/access-accounts'));
const Campaigns = lazy(() => import('src/views/campaigns'));
const Presences = lazy(() => import('src/views/presences'));
const Optimization = lazy(() => import('src/views/optimization'));
const Support = lazy(() => import('src/views/support'));

export const getRoutes = () => {
  return mergeRoutes([
      {
          path: '/boostad',
          element: (
              <ServiceGuard serviceIdentifier={'BOOSTAD'}>
                  <ServiceLayout
                      sections={[{
                          subheader: null,
                          items: [
                              {
                                  title: "common.overview",
                                  path: '/boostad',
                                  icon: <OnIcon size="small" iconName="BarChartCircle02" />,
                              },
                              {
                                  title: "marketing.optimization.title",
                                  path: '/boostad/optimization',
                                  icon: <OnIcon size="small" iconName="Rocket02" />,
                              },
                              {
                                  title: "marketing.online_presences.title",
                                  path: '/boostad/presences',
                                  icon: <OnIcon size="small" iconName="CursorBox" />,
                              },
                              {
                                  title: "marketing.campaigns.title",
                                  path: '/boostad/campaigns',
                                  icon: <OnIcon size="small" iconName="ChartBreakoutSquare" />,
                              },
                              {
                                  title: "marketing.platforms.access_accounts",
                                  path: '/boostad/accounts',
                                  icon: <OnIcon size="small" iconName="Key02" />,
                              },
                          ],
                      }]}
                      menuItems={[
                          {
                              title: "marketing.platforms.access_accounts",
                              path: '/boostad/accounts',
                              icon: <OnIcon size="small" iconName="Key02" />,
                          },
                      ]}
                      actionButton={{
                          title: 'Wir sind für Dich da',
                          subheader: 'Bei allen Fragen und Anliegen zum Online Marketing',
                          buttonLabel: 'Support Anfordern',
                          buttonPath: '/boostad/support',
                          icon: 'LifeBuoy02'
                      }}
                  >
                      <Suspense fallback={<LoadingScreen />}>
                          <Outlet />
                      </Suspense>
                  </ServiceLayout>
              </ServiceGuard>
          ),
          children: [
              {
                  path: '/boostad',
                  element: <Overview />
              },
              {
                  path: '/boostad/presences',
                  element: <Presences />
              },
              {
                  path: '/boostad/optimization',
                  element: <Optimization />
              },
              {
                  path: '/boostad/campaigns',
                  element: <Campaigns />
              },
              {
                  path: '/boostad/accounts',
                  element: <AccessAccounts />
              },
              {
                  path: '/boostad/support',
                  element: <Support />
              },
          ]
      }
  ])
}